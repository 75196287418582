<template>

  <section id="reports">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 section-head d-flex align-items-center">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0"> 
          {{ title_component.subtitle }}
        </h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-xl-3 mb-3">
        <v-select 
          class="selvue-custom"
          :options="indicadores_list" 
          v-model="indicadores_selected"
          :clearable="false">
        </v-select>
      </div>
      <div class="col-12 col-xl-9">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-3 mb-3">
            <v-select 
              class="selvue-custom"
              :options="cantidad_list" 
              v-model="cantidad_selected"
              :clearable="false">
            </v-select>
          </div>
          <div 
            v-if="cantidad_selected.label == 'Histórico'"
            class="col-12 col-lg-7 col-xl-8"></div>
          <div 
            v-if="cantidad_selected.label == 'Anual'"
            class="col-12 col-lg-7 col-xl-4 offset-xl-4 mb-3">
            <v-select 
              class="selvue-custom"
              :options="year_list" 
              v-model="year_selected"
              :clearable="false">
            </v-select>
          </div>
          <div 
            v-if="cantidad_selected.label == 'Rango'"
            class="col-12 col-lg-7 col-xl-8">
            <div class="row">
              <div class="col-12 col-lg-4 col-xl-6 d-flex align-items-center justify-content-lg-end text-secondary">
                <font-awesome-icon 
                    icon="filter" 
                    class="color-secondary pe-1"
                  />
                  Filtrar por periodo:
              </div>
              <div class="col-12 col-lg-8 col-xl-6 mb-3 mb-lg-0">

                <div class="row">
                  <div class="col-6">
                    <InputDate 
                      :initialDate="range.date_start"
                      :max="range.date_end"
                      placeHolder="Inicio"
                      @dateEmit="captureDateStart"
                    />
                  </div>
                  <div class="col-6">
                    <InputDate 
                      :initialDate="range.date_end"
                      :min="range.date_start" 
                      placeHolder="Término"
                      @dateEmit="captureDateEnd"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2 col-xl-1 mb-3">
            <a 
              @click="getReport(true)"
              href="javascript:"
              class="btn btn-custom btn-custom-color-blue border-round-50 flex-fill">
              <font-awesome-icon icon="download"/>
            </a>
          </div>
        </div>
      </div>
    </div>

    <Loading 
      v-if="loading"
      pixeles="150px"
    />

    <template v-if="!loading">   
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="card card-shadow border-round-10 border-0 mb-3">
            <div class="card-body">
              <div class="row d-flex align-items-center mb-3">
                <div class="col-12 col-lg-7">
                  <h5 class="font-main-bold color-gray">Detalle reconocimientos</h5>
                </div>
                <div class="col-12 col-lg-5 input-search">
                  <input 
                      type="text" 
                      placeholder="Buscar un colaborador" 
                      class="form-control input-custom"/>
                      <span class="input-search-icon">
                        <font-awesome-icon icon="search"/>
                      </span>
                </div>
              </div>
              <div 
                v-if="reportData.length !== 0"
                class="list-group">
                <div                     
                  v-for="(el, i) in reportData" :key="i">
                  <a 
                    href="javascript:" 
                    @click="reporte_selected = el"
                    class="list-group-item list-group-item-action border-0 border-bottom">
                      <div class="d-flex flex-column flex-xxl-row align-items-xxl-center">
                        <div class="d-flex flex-grow-1 align-items-center">
                          <div class="flex-shrink-0">
                            <img
                              class="img-obj img-obj-60 img-obj-circle border"
                              v-if="el.reconocidos[0].imagen_perfil"
                              :src="el.reconocidos[0].imagen_perfil"
                            />
                            <img
                              class="img-obj img-obj-60 img-obj-circle"
                              v-if="!el.reconocidos[0].imagen_perfil"
                              :src="user_default_img"
                            />
                          </div>
                          <div class="flex-grow-1 ms-3">
                            <h5 class="card-subtitle font-main color-gray">
                              {{ el.reconocidos[0].primer_nombre | capitalize }}
                              {{ el.reconocidos[0].apellido_paterno | capitalize }} 
                              ha sido
                              <img width="35" height="35" src="@/assets/img/reconoze/ball-star.svg">
                              por 
                              <img class="img-obj img-obj-30 img-obj-circle" :src="el.reconocedor[0].imagen_perfil" v-if="el.reconocedor[0].imagen_perfil"/>
                              <img class="img-obj img-obj-30 img-obj-circle" :src="user_default_img" v-else/>
                              {{ el.reconocedor[0].primer_nombre | capitalize }}
                              {{ el.reconocedor[0].apellido_paterno | capitalize }}
                            </h5>
                            <p class="card-text color-light">{{ dateFormat(el.fecha_creacion) }}</p>
                          </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-end p-3">
                          <font-awesome-icon icon="heart" class="fs-4 color-rose-shadow"/>
                          <span class="color-light px-2">{{ el.likes.length }}</span>
                          <a 
                            class="link-secondary text-decoration-none"
                            href="javascript:">
                            Ver detalle
                          </a>
                        </div>
                      </div>
                  </a>
                </div>
              </div>
  
              <SinResultados
              v-if="!loading && reportData.length === 0"/>

            </div>
          </div>

          <c3-paginate 
            :pagination="pagination" 
            @pageChanged="onPageChange"
          />
        </div>
        <div class="col-12 col-lg-4">
          <div class="card card-shadow border-round-10 border-0 mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <h5 class="font-main-bold color-gray mb-3">Información del usuario</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-xxl-6 mb-3">
                  <label>Reconocimiento</label>
                  <input 
                    v-if="reporte_selected == null"
                    type="text" 
                    value=""
                    class="form-control input-custom"/>
                    <input 
                      v-else
                      :value="`${reporte_selected.valor_comportamiento[0].valor.nombre_valor}`"
                      type="text" 
                      class="form-control input-custom"/>
                </div>
                <div class="col-12 col-xxl-6 mb-3">
                  <label>Relación</label>
                  <input 
                    v-if="reporte_selected == null" 
                    type="text" 
                    value=""
                    class="form-control input-custom"/>
                    <input 
                      v-else
                      type="text" 
                      :value="`${reporte_selected.relacion[0].nombre_relacion}`"
                      class="form-control input-custom"/>
                </div>
              </div>
  
              <div class="row">
                <div class="col-12 mb-3">
                  <label>Argumento</label>
                  <textarea 
                    v-if="reporte_selected == null"
                    value=""
                    class="form-control textarea-custom textarea-custom-resize">
                  </textarea>
                  <textarea 
                    v-else
                    :value="`${reporte_selected.comentario_reconocimiento}`"
                    class="form-control textarea-custom textarea-custom-resize">
                  </textarea>
                </div>
              </div>
  
              <div 
                v-if="reporte_selected !== null" 
                class="row">
                <div class="col-12">
                  <div 
                    v-if="reporte_selected.url_imagen === ''" 
                    class="alert alert-warning mb-0 text-center">
                    <font-awesome-icon icon="circle-info"/>
                    No hay imagen en esta publicación
                  </div>
                  <img 
                    class="img-fluid img-thumbnail"
                    v-if="reporte_selected.url_imagen !== ''" 
                    :src="reporte_selected.url_imagen"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
  import SectionTabs from "../Section/SectionTabs.vue";
  import * as XLSX from 'xlsx'
  import { mapActions } from "vuex";
  import moment from "moment";

  //FF
  import InputDate from "@/components/forms/InputDate.vue"
  import Loading from "@/components/Loading.vue";
  import C3Paginate from "@/components/C3Paginate.vue";
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: {
      SectionTabs,
      C3Paginate,

      //FF
      InputDate,
      Loading,
      SinResultados
    },

    data() {
      return {
        tab_component: [
          {
            name: "Dashboard",
            route: "recognize-Dashboard",
          },
          {
            name: "Indicadores",
            route: "recognize-reports",
          },
        ],
        reportData: [],
        reporte_selected: null,
        indicadores_list: [
          { label: "Año", route: "recognize-year" },
          { label: "Gerencia", route: "recognize-management" },
          { label: "Planta", route: "recognize-age" },
          { label: "Top 20", route: "recognize-ranking" },
          { label: "Consultas por colaborador", route: "recognize-contributor" },
          { label: "Detalles de reconocimiento", route: "recognize-reports" },
        ],
        indicadores_selected: "Detalles de reconocimiento",
        cantidad_list: [
          { label: "Anual", id: "1" },
          { label: "Rango", id: "2" },
          { label: "Histórico", id: "3" },
        ],
        cantidad_selected: { label: "Anual", id:"1" },

        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        title_component: {
          title: "Reconoce",
          subtitle:
            "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
        },
        year_list: ["2022", "2023", "2024", "2025"],
        year_selected: `${new Date().getFullYear()}`,
        user_default_img: require("@/assets/img/user_default_img.svg"),

        pagination: {
          actual_page: 1
        },

        //FF
        loading: true,
        range: {
          date_start: new Date(`${moment.utc().startOf('month').format('YYYY-MM-DD')} 12:00:00`),
          date_end: new Date(`${moment.utc().endOf('month').format('YYYY-MM-DD')} 12:00:00`)
        }

      };
    },
    watch: {
      year_selected() {
        this.getReport();
      },
      "range.date_start"() {
        this.getReport();
      },
      "range.date_end"() {
        this.getReport();
      },
      cantidad_selected() {
        this.getReport();
      },
      indicadores_selected(value) {
        this.$router.push({ name: value.route });
      }
    },

    async created() {
      await this.getReport();
    },

    methods: {
      ...mapActions("recognizeModule", ["getReconocimientosReporteAction"]),
      
      async getReport(downloadReport=false) {
        this.loading = true;
        var f_ini = "";
        var f_end = "";

        if (this.cantidad_selected.label === "Anual") {
          f_ini = `${this.year_selected}-01-01`;
          f_end = `${this.year_selected}-12-31`;
        } else if (this.cantidad_selected.label === "Rango") {
          f_ini = this.range.date_start ? moment(this.range.date_start).format('YYYY-MM-DD') : '',
          f_end = this.range.date_end ? moment(this.range.date_end).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD')
        } else if (this.cantidad_selected.label === "Histórico") {
          f_ini = "";
          f_end = "";
        }

        const payload = {
          id_empresa: this.id_empresa,
          nombre_usuario: "",
          fecha_inicio: f_ini,
          fecha_fin: f_end,
          pagina: +this.pagination.actual_page
        };
        try {
          const { data, ...paginationData } = await this.getReconocimientosReporteAction(payload);
          this.pagination = { ...this.pagination, ...paginationData };

          if (!downloadReport) {
            this.reportData = data;
          } else {
            this.dataFormat(data);
          }

        } catch (error) {
          this.toastGenericError();
          console.error(error);
        }

        this.loading = false;
      },

      onPageChange() {
        this.getReport();
      },
      
      dataFormat(res) {
        var report = {};
        var report_arr = [];
        var title = `Reconocimientos_Totales`;
        for (var i = 0; i < res.length; i++) {
          var reconocido = res[i].reconocidos[0];
          var reconocedor = res[i].reconocedor[0];

          report = {
            comentario: res[i].comentario_reconocimiento,
            valor: res[i].valor_comportamiento[0].valor.nombre_valor,
            relacion: res[i].relacion[0].nombre_relacion,
            fecha_creacion: this.dateFormat(res[i].fecha_creacion),
            likes: res[i].likes.length,

            reconocido_nombre: reconocido.primer_nombre,

            reconocido_apellido_pa: reconocido.apellido_paterno,
            reconocido_apellido_ma: reconocido.apellido_materno,
            reconocido_rut: `${reconocido.rut_usuario}-${reconocido.dv_usuario}`,
            reconocido_cargo:
              reconocido.cargo == null
                ? "Sin cargo"
                : reconocido.cargo.nombre_cargo,
            reconocido_gerencia:
              reconocido.gerencia == null
                ? "sin gerencia"
                : reconocido.gerencia.nombre_gerencia,

            reconocedor_nombre: reconocedor.primer_nombre,
            reconocedor_apellido_pa: reconocedor.apellido_paterno,
            reconocedor_apellido_ma: reconocedor.apellido_materno,
            reconocedor_rut: `${reconocedor.rut_usuario}-${reconocedor.dv_usuario}`,
            reconocedor_cargo:
              reconocedor.cargo == null
                ? "Sin cargo"
                : reconocedor.cargo.nombre_cargo,
            reconocedor_gerencia:
              reconocedor.gerencia == null
                ? "sin gerencia"
                : reconocedor.gerencia.nombre_gerencia,
          };
          report_arr.push(report);
          report = {};
        }

        this.printJson(report_arr, title);
      },

      dateFormat(date) {
        if (date === undefined || date === null) {
          return "";
        } else {
          return moment(date).format('DD/MM/YYYY');
        }
      },
      printJson(json, filename) {
        let data = XLSX.utils.json_to_sheet(json);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      },

      //FF
      captureDateStart(date) {
        this.range.date_start = date;
      },
      captureDateEnd(date) {
        this.range.date_end = date;
      }
    },
    //FF
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString().toLowerCase();
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    }
  };
</script>
